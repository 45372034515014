<template>
    <div class="register-box">
        <div class="card">
            <div class="card-body register-card-body">
                <p class="login-box-msg"><h3 class="text-center">Aktivasi User</h3></p>
                <div class="text-center" :class="alertStatus" v-if="showMessage">{{ textMessage }}</div>
                <br>
                <div class="text-center">
                    <router-link to="/login" class="text-center">Kembali ke halaman login</router-link>
                </div>
            </div>
            <!-- /.form-box -->
        </div><!-- /.card -->
    </div>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';

import $ from 'jquery';
export default {
    name: 'UserActivate',
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            disabled: false,
            groups: [],
            showMessage: true,
            textMessage: 'Periksa...',
            alertStatus: '',
            form: {
                id: '',
                key: '',
                pass: '',
                pass2: ''
            }
        }
    },
    computed: {},
    created: function() {
        $('body').addClass('register-page');
        $('body').removeAttr('style');
        this.form.id = this.$route.params.id;
        if(this.form.id)
            this.checkData(this.form.id);
    },
    mounted() {

    },
    beforeDestroy() {
        $('body').removeClass('register-page');
    },
    methods: {
        checkData: function(id) {
            fetch(window.$apiUrl + '/users/register/activate?code='+id)
            .then(res => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
            })
            .then(js => {
                if(js.status){
                    this.textMessage = js.message;
                    this.alertStatus = 'alert alert-success';
                }
                else{                    
                    this.textMessage = js.message;
                    this.showMessage = true;
                    this.alertStatus = 'alert alert-danger';
                    this.disabled = true;
                }
              
            });
        },
        resetForm: function() {
            for (const key in this.$refs) {
                this.$refs[key].setAttribute('title', '');
                this.$refs[key].classList.remove('is-invalid');
            }
        }
    }
}
</script>